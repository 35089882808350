@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: BigOwl;
  src: url("./assets/fonts/Title/Big\ Owl\ Regular.ttf");
}

@font-face {
  font-family: Montserrat-ExtraBold;
  src: url("./assets/fonts/Description/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: Montserrat-ExtraBoldItalic;
  src: url("./assets/fonts/Description/Montserrat-ExtraBoldItalic.ttf");
}
@font-face {
  font-family: Montserrat-Black;
  src: url("./assets/fonts/Description/Montserrat-Black.ttf");
}
@font-face {
  font-family: Montserrat-BlackItalic;
  src: url("./assets/fonts/Description/Montserrat-BlackItalic.ttf");
}
@font-face {
  font-family: Montserrat-Bold;
  src: url("./assets/fonts/Description/Montserrat-Bold.ttf");
}
@font-face {
  font-family: Montserrat-BoldItalic;
  src: url("./assets/fonts/Description/Montserrat-BoldItalic.ttf");
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("./assets/fonts/Description/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: Montserrat-SemiBoldItalic;
  src: url("./assets/fonts/Description/Montserrat-SemiBoldItalic.ttf");
}
@font-face {
  font-family: Montserrat-Regular;
  src: url("./assets/fonts/Description/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Italic;
  src: url("./assets/fonts/Description/Montserrat-Italic.ttf");
}

* {
  margin: 0;
  padding: 0;
  font-family: Montserrat-Regular;
  scroll-behavior: smooth;
  box-sizing: border-box;
  text-decoration: none;
}
.line-clamp {
  --max-lines: 4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
